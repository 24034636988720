"use client";

import { ThemeProvider } from "@mui/material/styles";
import { getTheme } from "@cbex/utils/theme";
import React from "react";
import { CssBaseline } from "@mui/material/";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "b6be02d9e2d1d53bf0a8e65f4466fa8fTz01NjY4MCxFPTE3MDM2NjQ3NDkzMjQsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export const ClientProviders = ({ children }: { children?: any }) => (
  <ThemeProvider theme={getTheme()}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
