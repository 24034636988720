import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/libs/utils/approuterconfig/src/lib/ClientProviders.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/libs/utils/approuterconfig/src/lib/EmotionCacheProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/libs/utils/approuterconfig/src/lib/IntlProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/libs/utils/approuterconfig/src/lib/ScevClientProviders.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/node_modules/@fontsource/roboto/300.css");
;
import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/node_modules/@fontsource/roboto/400.css");
;
import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/node_modules/@fontsource/roboto/500.css");
;
import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/node_modules/@fontsource/roboto/700.css");
;
import(/* webpackMode: "eager" */ "/tmp/build/80754af9/repo/node_modules/@fontsource/material-icons/index.css");
